<template>
  <div class="case-container">
    <header class="mycase-header" v-if="showHeader">
      <van-nav-bar
        title="案例详情"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
        right-text="分享"
        @click-right="handleShare"
      >
      </van-nav-bar>
    </header>
    <div class="handle-article">
      <div @click="handleCaseEditor">
        <icon-svg iconClass="iconedit-3" class="editor" />编辑
      </div>
      <div @click="handleShare">
        <icon-svg iconClass="iconshare-2" class="share" />分享
      </div>
    </div>
    <article class="case-details" v-if="loadingCase">
      <van-tabs :border="false" swipeable background="#fff" title-active-color="rgb(255, 110, 1)">
        <van-tab title="案例详情">
          <div class="case-base-info">
            <div class="case-info-card">
              <!-- 封面图 -->
              <div class="mycase-box-info">
                <div class="case-box">
                  <div class="cover-img">
                    <div
                            class="house-tour"
                            v-if="oneCase.panoramicUrl"
                            @click="loadPanoramic"
                    >
                      全景漫游
                    </div>
                    <img
                            :src="oneCase.headImgUrl?`${imgUrl}/${oneCase.headImgUrl}`:morenImg"
                            @click="loadCoverImage"
                    />
                  </div>
                  <div class="case-info">
                    <div class="title">
                      <p>{{ oneCase.title }}</p>
                      <p>
                        {{ oneCase.typeName }} | {{ oneCase.area }}m² |
                        {{ oneCase.casePosition }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 名片 -->
            <van-sticky :offset-top="topSpace">
              <van-skeleton title avatar :row="3" v-if="showSkeleton"></van-skeleton>
              <div v-else class="case-namecard" >
                <div class="img-name" @click="loadUserCard">
                  <img
                          :src="
                  oneCase.userCard.headImgUrl
                    ? `${imgUrl}/${oneCase.userCard.headImgUrl}`
                    : morenHeadImg
                "
                          alt=""
                  />
                  <div class="creater-info">
                    <p>{{ oneCase.userCard.name }}</p>
                    <p>{{ oneCase.userCard.merchantName }}</p>
                  </div>
                </div>
                <div class="create-describe">
                  <span>{{ isFold ? foldSynopsisStr : synopsisStr }}</span>
                  <span style="color:#999" @click="handleIsFold" v-if="synopsisStr.length>40">{{
                  isFold ? "...展开" : "&lt;&lt;折叠"
                }}</span>
                </div>
              </div>
            </van-sticky>
          </div>
          <div class="case-design">
            <p class="title">设计理念</p>
            <div class="decribe-text">
              {{ oneCase.idea }}
            </div>
            <div class="case-content">
              <div class="case-content-box">
                <transform-html :contents="oneCase.picDescription"></transform-html>
              </div>
            </div>
            <!-- <div class="case-design-details">
                    <p>客厅</p>
                    <div class="cover-img">
                            <img src="../../assets/icons/mimi.jpg" alt="">
                    </div>
                    <div class="decribe-text">空间生命力随着这些元素逐渐清晰 时髦又优雅的现代精神 越发清晰。</div>
               </div> -->
            <!-- <div class="case-design-details">
                    <p>客厅</p>
                    <div class="cover-img">
                            <img src="../../assets/icons/mimi.jpg" alt="">
                    </div>
                    <div class="decribe-text">空间生命力随着这些元素逐渐清晰 时髦又优雅的现代精神 越发清晰。</div>
               </div> -->
          </div>
        </van-tab>
        <van-tab title="传播轨迹">
          <div class="related-clues">
            <p v-if="withClueList.length">关联线索</p>
            <van-list v-if="withClueList.length">
              <van-cell v-for="it in withClueList" :key="it">
                <div class="clues-box">
                  <div class="img-name">
                    <img
                            :src="it.headImgUrl ? it.headImgUrl : morenHeadImg"
                            alt=""
                    />
                    <div class="creater-info">
                      <p>{{ it.name }} {{
                        it.phone
                        ? `${it.phone.slice(0, 3)}****${it.phone.slice(7)}`
                        : ""
                        }}</p>
                    </div>
                  </div>
                  <div class="clues-details" @click="loadCuleSDetail(it.clueId)">
                    <p>
                      浏览时间：{{
                      it.browseTime
                      ? `${it.browseTime.slice(0, 10)} ${it.browseTime.slice(
                      11,
                      19
                      )}`
                      : ""
                      }}
                    </p>
                    <p>浏览时长：{{ it.browseSecond }}秒</p>
                    <p>浏览位置：{{it.clueShareSource}}</p>
                    <p>分享人：{{ it.createName }}</p>
                    <p>推荐人：{{it.recommend_Name}}</p>
                  </div>
                </div>
              </van-cell>
            </van-list>
            <div v-else class="no-clues">
              <img :src="nodataImg">
            </div>
          </div>
        </van-tab>
      </van-tabs>


    </article>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <!--    分享海报-->
      <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
        <div class="poster-details" v-if="showImg" ref="imageWrapper">
          <img :src="posterPic" crossOrigin="anonymous"/>
          <div class="text-qrcode">
            <div class="user-info-tag">
              <div class="user-info">
                <img :src="headImgUrl">
                <div class="left-text">
                  <p>{{ userForm.name }}</p>
                  <p>{{ userForm.merchantName }}</p>
                </div>
              </div>
              <div class="user-tag">
                <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
              </div>
            </div>
            <div class="qrCodeUrl-all">
              <div class="qrCodeUrl-cls">
                <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
              </div>
              <p>立即扫码</p>
              <p>了解更多内容</p>
            </div>
          </div>
        </div>
        <div v-else class="posterImg-hb">
          <img class="card-img" :src="imgSrc" ref="cardImgRef" />
        </div>
      </div>
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="shareOptions"
        cancel-text="取消"
        @select="onSelect"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
  </div>
</template>
<script>
import {reactive, toRefs, computed, watch, nextTick, ref} from "vue";
import { useRoute, useRouter } from "vue-router";
import { findCaseById,getCaseDetailsUrl } from "../../api/case";
import transformHtml from "../../components/transformHTML.vue";
import { shareCaseToMiniProgram,copyStr,loadImages } from "@/utils/commonUtil";
import { caseReprint,createPosterAppShare,getQrCodeUrl } from "@/api/common";
import {ImagePreview, Toast} from "vant";
import {flutterShowToast} from '../../utils/appChannel'
import {posterShare} from "@/utils/posterShare"
import html2canvas from "html2canvas";
import {findPosterImg} from "../../api/file";
import {getUserCardByPoster} from "@/api/user/userCard"

export default {
  components: {
    transformHtml,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const state = reactive({
      userForm:{},
      userTags:[],
      headImgUrl:'',
      nodataImg:require("../../assets/images/no-data.png"),
      showSharePoster:false,
      shareJumpUrl:'',
      posterId:'',
      imgSrc:'',
      qrCodeUrl:'',
      posterName:'',
      posterPic:'',
      sharePosterImg:'',
      showOverlay: false,
      showImg: true,
      shareOptions:[],
      defaultImg: require("@/assets/images/case-defaultimg.png"),
      //=======================分享海报
      showSkeleton:true,
      topSpace: 43,
      withClueList: {},
      coverImage: [],
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      morenHeadImg: require("../../assets/icons/Vector.png"),
      morenImg: require('../../assets/images/case-defaultimg.png'),
      loadingCase: false,
      showShare: false,
      caseId: route.query.caseId,
      oneCase: [],
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        { name: "分享海报", icon: require("@/assets/icons/share_poster.png") },
      ],
      posterOptions: [
        {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
        {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
      ],
      synopsisStr: "",
      foldSynopsisStr: "",
      isFold: true,
    });
    watch(() => state.showShare, (newVal, oldVal) => {
      console.log('新值：',newVal,'老值',oldVal)
      if (state.showShare===false){
        state.showOverlay = false
        state.showImg = true
      }
    })
    //个人名片
    const loadUserCard = () => {
      router.push(`/userCard`);
    };
    //全景漫游
    const loadPanoramic = () => {
      router.push({
        name: "panoramicWindow",
        query: { panoramicUrl: state.oneCase.panoramicUrl },
      });
    };
    //展开折叠
    const handleIsFold = () => {
      state.isFold = !state.isFold;
    };
    //返回
    const handleBlack = () => {
      if(route.params.addPoster === 'true'){
        router.push({
          path:'/caseVisitors',
          name: 'caseIndex',
          params:{
            addPoster:true,
          }
        })
        return
      }
      if(route.params.fromPoster === 'true'){
        router.push({
          path:'/caseVisitors',
          name: 'caseIndex',
          params:{
            fromPoster:true,
          }
        })
        return
      }
      if(route.query.from === 'card'){
        router.back()
      }else{
         if (route.query.comfrom === "search") {
          router.push({
            name: "caseSearch",
            query: {
              searchVal: route.query.searchVal,
              caseType: route.query.caseType,
              twoType: route.query.twoType,
            },
          });
      } else {
          router.push({
            name: "caseIndex",
            params: {
              caseType: route.query.caseType,
              twoType: route.query.twoType,
              caseTypeStr: route.query.caseTypeStr,
            },
          });
        }
      }

    };

    let showHeader = computed(() => route.query.hideTitleBar != 1);
    if (!showHeader.value) {
      state.topSpace = 0;
    }
    //获取案例详情
    const loadCaseDetails = async () => {
      let result = await findCaseById(state.caseId);
      // state.posterPic = result.headImgUrl?state.imgUrl+"/"+result.headImgUrl:state.defaultImg
      // state.sharePosterImg = result.headImgUrl?state.imgUrl+"/"+result.smallCoverPic:"https://wca.ujiaku.com/upload/app_defaultImg/case-default.png"
      state.posterName = result.title
      state.oneCase = result;
      state.loadingCase = true;
      state.synopsisStr = result.userCard.selfIntro?result.userCard.selfIntro:'我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！';
      state.foldSynopsisStr = state.synopsisStr.substring(0, 44);
      state.withClueList = result.clueList;
      state.coverImage[0] =result.headImgUrl? `${state.imgUrl}/${result.headImgUrl}`:state.morenImg;
      state.showSkeleton = false
      const p = {
        busiId:state.caseId,
        busiCat:'poster',
        groupId:'sharePosterPic',
      }
      findPosterImg(p).then((res)=>{
        if (res.length>0){
          //获取小图
          findPosterImg({
            busiId:state.caseId,
            busiCat:'poster',
            groupId:'sharePosterPic:S',
          }).then((sImg)=>{
            state.sharePosterImg = sImg[0].url?state.imgUrl+"/"+sImg[sImg.length-1].url:"https://wca.ujiaku.com/upload/app_defaultImg/case-default.png"
          })
          state.posterPic = res[0].url?state.imgUrl+"/"+ res[0].url:state.defaultImg
        }else {
          state.posterPic = state.defaultImg
          state.sharePosterImg = "https://wca.ujiaku.com/upload/app_defaultImg/case-default.png"
        }
      })
    };
    loadCaseDetails();

     //线索跳转详情
    const loadCuleSDetail = (id)=>{
       let params = {
        shareType: "openCustomPage",
        pageName: "clue_detail_page",
        param:{ clueId: id},
      };
      flutterShowToast(params)
    }
    //加载封面图
    const loadCoverImage = () => {
      ImagePreview({
        images: state.coverImage,
        showIndex: false,
      });
    };
    const loadPoster = ()=>{
      savePoster()
      setTimeout(()=>{
        Toast.clear()
      })
    }
    //打开分享微信
    const handleShare = () => {
      state.showShare = true;
      state.shareOptions = state.options
      getUserCardByPoster().then(res=>{
        if (res){
          if (res.headImgUrl){
            state.headImgUrl = state.imgUrl+'/'+res.headImgUrl
          }else {
            state.headImgUrl = require("@/assets/poster_headerImg_default.png")
          }
          state.userForm = res
          state.userTags = res.impressionLabel.split(',')
          if (state.userTags.length>4){
            state.userTags = state.userTags.slice(0,4)
          }
        }
      })
      let params = {
        sourceId:state.caseId,//分享的id
        sourceType:2,//来源类别 1-文章 2-案例 3-视频 4-工地
      }
      getQrCodeUrl(params).then((res)=>{
        state.shareJumpUrl = res.jumpUrl
        if(res.qrCodeUrl){
          state.qrCodeUrl = res.qrCodeUrl
        }
        state.showSharePoster = true
      })
    };
    //微信分享
    const onSelect = (index) => {
      // if (index.name === "微信") {
      //   // router.push({path:`/caseShare/${state.caseId}`})
      //   shareCaseToMiniProgram(state.oneCase);
      // }
      if(index.name === "微信分享"||index.name === "朋友圈分享"){
        if(state.showSharePoster===true){
          Toast.loading({
            message: '正在\n打开微信...',
            forbidClick: true,
            duration:3000,
            className:'posterToast',
          });
          let p = {
            sourceId: state.caseId,//分享的id
            sourceType:2,//来源类别 1-文章 2-案例 3-视频 4-工地
            jumpUrl:state.shareJumpUrl
          }
          createPosterAppShare(p).then((res)=>{
            console.log("创建二海报",res)
          })
          let param = {
            imgSrc:state.imgSrc,
            id:state.caseId,
            bigPosterImgUrl:state.sharePosterImg,
          }
          setTimeout(()=>{
            posterShare(index,param)
          })
        }
      }
      else if(index.name === "复制链接"){
        getCaseDetailsUrl(state.caseId).then((res)=>{
          console.log("链接详情",res)
          copyStr(res)
          state.showShare = false
        })
      }else if(index.name === "分享海报"){
        if(state.showSharePoster===true){
          Toast.loading({
            message: '生成海报中...',
            forbidClick: true,
            duration:0,
            className:'posterToast',
          });
          state.shareOptions = state.posterOptions
          // state.showImg = true
          state.showOverlay = true;
          // setTimeout(()=>{
          //   savePoster()
          // },200)
          let arr = []
          arr.push(state.posterPic)
          arr.push(state.qrCodeUrl)
          loadImages(arr,loadPoster)
        }
      }else {
        caseReprint({ busiId: state.caseId });
        shareCaseToMiniProgram(state.oneCase, index);
      }
    };
    const handleCaseEditor = () => {
      router.push({
        path: "/caseVisitors/caseEditor",
        query: {
          caseId: state.caseId,
          caseType: route.query.caseType,
          twoType: route.query.twoType,
        },
      });
    };
    //画图
    const savePoster = () =>{
      console.log("保存")
      state.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        html2canvas(_canvas, {
          useCORS: true,
          background: "#ffffff",
          height: document.getElementById('saveposter').scrollHeight,
          windowHeight: document.getElementById('saveposter').scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
            state.showImg = false
          });
        });
      });
    }
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };

    return {
      imageWrapper,
      cardImgRef,
      savePoster,
      DPR,
      handleIsFold,
      loadCuleSDetail,
      ...toRefs(state),
      handleBlack,
      handleShare,
      onSelect,
      handleCaseEditor,
      loadCoverImage,
      loadPanoramic,
      loadUserCard,
      showHeader,
    };
  },
};
</script>
<style lang="scss">
.posterToast{
  //color: #289fff;
  z-index: 1000000001 !important;
}
.case-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  //   padding: 0 16px;
  .mycase-header {
    font-size: 18px;
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .handle-article {
    position: fixed;
    right: 0;
    bottom: 110px;
    z-index: 100;
    div {
      height: 28px;
      width: 54px;
      color: #ffffff;
      font-size: 12px;
      opacity: 0.6;
      border-radius: 50px 0 0 50px;
      margin: 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    div:nth-child(1) {
      background: #ff6e01;
    }
    div:nth-child(2) {
      background: #ff6e01;
    }
  }
  .case-details {
    flex: 1;
    overflow-x: hidden;
    .van-tabs__line{
      background-color: #ff6e01;
    }
    .case-base-info {
      // padding: 0 16px;
      border-bottom: 1px solid #f7f7f7;
      .case-namecard {
        background-color: #ffff;
        margin: 0 16px;
        margin-bottom: 20px;
        padding: 0 16px;
        box-shadow: 0px 5px 15px rgb(229, 229, 230);
        border-radius: 8px;
        .img-name {
          height: 40px;
          padding: 5px 0;
          img {
            display: block;
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 20px;
            float: left;
          }
          .creater-info {
            float: left;
            margin-left: 10px;
            font-size: 14px;
            text-align: left;
            p {
              margin: 0;
            }
            > p:nth-child(1) {
              font-weight: 600;
            }
            > p:nth-child(2) {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .create-describe {
          font-size: 12px;
          text-align: left;
          padding: 5px 16px;
        }
      }
      .mycase-box-info {
        flex: 1;
        padding: 5px 16px;
        .case-box {
          font-size: 16px;
          text-align: left;
          .cover-img {
            height: 160px;
            position: relative;
            .house-tour {
              position: absolute;
              top: 50%;
              left: 50%;
              color: #fff;
              transform: translate(-50%, -50%);
              background: rgba(31, 30, 30, 0.5);
              opacity: 0.8;
              height: 20px;
              border-radius: 20px;
              font-size: 14px;
              padding: 8px 30px;
            }
            > img {
              display: block;
              border-radius: 4px;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .case-info {
            //  height: auto;
            position: relative;
            display: flex;
            width: 343px;
            justify-content: space-between;
            .title {
              //  float: left;
              position: relative;
              width: 330px;
              > p {
                margin: 0;
                padding: 0;
              }
              > p:nth-child(1) {
                font-weight: 600;
                margin-top: 8px;
              }
              > p:nth-child(2) {
                font-weight: 400;
                font-size: 14px;
                margin: 4px 0;
                color: #666666;
              }
            }
            .share-del {
              top: 10px;
              position: relative;
              .share {
                height: 20px;
                width: 20px;
                background: url("../../assets/icons/share-3.png") no-repeat;
              }
              .del {
                height: 20px;
                margin-top: 15px;
                width: 20px;
                background: url("../../assets/icons/trash-2.png") no-repeat;
              }
            }
          }
        }
      }
    }
    .case-design {
      padding: 5px 16px;
      padding-bottom: 0;
      text-align: left;
      .title {
        margin: 0;
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 600;
      }
      .decribe-text {
        color: #666;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .case-content {
        width: 100%;
        font-size: 14px;
        text-align: left;
        box-sizing: border-box;
        background-color: #ffffff;
        .case-content-box {
          // height: 300px;
          // background-color: deeppink;
          //img{
          //  width: 100%;
          //}
          //p {
          //  margin: 0;
          //  padding: 0;
          //  font-size: 14px;
          //  font-weight: 400;
          //}
          border-bottom: 1px solid #f1f3f6;
        }
      }
      .case-design-details {
        .cover-img {
          height: 160px;
          > img {
            display: block;
            border-radius: 4px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        > span {
          color: #666;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .related-clues {
      text-align: left;
      padding: 5px 16px;
      //background: #f7f7f7;
      .van-cell {
        margin-bottom: 10px;
      }
      > p {
        //  margin: 16px 0;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 600;
      }
      .clues-box {
        //  margin-bottom: 10px;
        .img-name {
          height: 40px;
          padding: 5px 0;
          img {
            display: block;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            float: left;
          }
          .creater-info {
            float: left;
            margin-left: 10px;
            font-size: 14px;
            text-align: left;
            p {
              margin: 0;
            }
            > p:nth-child(1) {
              font-weight: 600;
            }
            > p:nth-child(2) {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
        .clues-details {
          //   background: #F7F7F7;
          background-color: #f7f7f7;
          padding: 5px;
          > p {
            margin: 0;
            font-size: 12px;
          }
        }
      }
      .no-clues{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85vh;
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
  .wrapperPoster{
    //margin-top: 100px;
    height: 400px;
    overflow-x: hidden;
    //position: absolute;
    position: fixed;
    top: 60px;
    //bottom: 0;
    left: 0;
    right: 0;
    .poster-details{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      overflow-x: hidden;
      >img{
        width: 250px;
        display: block;
      }
      //.text-qrcode{
      //  background-color: #ffff;
      //  display: flex;
      //  justify-content: space-between;
      //  padding: 10px;
      //  .left-text{
      //    text-align: left;
      //    p {
      //      margin: 0;
      //      padding: 0;
      //    }
      //    p:nth-child(1){
      //      font-size: 14px;
      //      font-weight: 400;
      //      line-height: 18px;
      //      color: #333333;
      //    }
      //    p:nth-child(2){
      //      font-size: 9px;
      //      color: #999999;
      //      line-height: 20px;
      //    }
      //  }
      //  .qrCodeUrl-all{
      //    position: relative;
      //    text-align: center;
      //    height: 60px;
      //    .qrCodeUrl-cls{
      //      width: 60px;
      //      height: 60px;
      //      background-color: #FFFFFF;
      //      border-radius: 30px;
      //      position: relative;
      //      top: -33px;
      //      text-align: center;
      //      img{
      //        width: 50px;
      //        border-radius: 25px;
      //        position: relative;
      //        margin-top: 50%;
      //        transform: translateY(-50%);
      //      }
      //    }
      //    >p{
      //      position: relative;
      //      font-size: 9px;
      //      font-weight: 400;
      //      color: #999999;
      //      top: -40px;
      //    }
      //  }
      //}
      .text-qrcode{
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        flex: 1;
        .user-info-tag{
          .user-info{
            display: flex;
            align-items: center;
            img{
              border-radius: 20px;
              width: 40px;
              height: 40px;
              object-fit: cover;
              flex-shrink: 0;
            }
            .left-text{
              text-align: left;
              //flex: 1;
              margin-left: 4px;
              p {
                margin: 0;
                padding: 0;
              }
              p:nth-child(1){
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                color: #333333;
              }
              p:nth-child(2){
                font-size: 10px;
                color: #333333;
                //line-height: 20px;
              }
            }
          }
          .user-tag{
            display: flex;
            flex-wrap: wrap;
            .user-tag-item{
              margin-top: 5px;
              margin-right: 5px;
              //padding-left: 5px;
              padding:2px 5px;
              color: #289FFF;
              background-color: #E6ECFF;
              font-size: 9px;
              border-radius: 4px;
            }
          }
        }


        .qrCodeUrl-all{
          //position: relative;
          //text-align: center;
          //height: 60px;
          border-left: 1px solid #F1F3F6;
          padding-left: 18px;
          margin-left: 8px;
          flex-shrink:0;
          .qrCodeUrl-cls{
            //width: 60px;
            //height: 60px;
            background-color: #FFFFFF;
            border-radius: 30px;
            //position: relative;
            //top: -33px;
            //text-align: center;
            img{
              width: 60px;
              //border-radius: 25px;
              //position: relative;
              //margin-top: 50%;
              //transform: translateY(-50%);
            }
          }
          >p{
            //position: relative;
            text-align: center;
            font-size: 10px;
            font-weight: 400;
            color: #666666;
            margin: 0;
            //top: -40px;
          }
        }
      }
    }
    .posterImg-hb{
      height: 400px;
      overflow-x: hidden;
    }
  }
}
</style>
